@import '../../scss/variables';
@import '../../scss/mixin';

.Modal {
  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    background: rgba($pure-black, 0.7);
    opacity: 1;
    pointer-events: all;
    z-index: 98;

    &.Modal__invisible {
      opacity: 0;
      z-index: -1;
      pointer-events: none;
    }
  }

  &__wrp {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    width: 90vw;
    height: calc(100vh - 100px);
    overflow-y: auto;
    max-width: 100%;
    max-height: 100%;
    z-index: 99;
    pointer-events: all;
    background: $light-black;
    border-radius: 5px;

    @include mq('sm') {
      width: 80vw;
    }

    @include mq('md') {
      width: 65vw;
    }

    @include mq('lg') {
      width: 55vw;
    }

    @include mq('ll') {
      max-width: 750px;
      width: 100%;
    }

    &.Modal__invisible {
      z-index: -1;
      pointer-events: none;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__closebtn {
    position: absolute;
    top: 2%;
    right: 2%;
    z-index: 1;
    display: inline-flex;
    padding: 8px;
    font-size: 18px;
    border-radius: 50%;
    cursor: pointer;
    margin: 0 5px;
    margin-bottom: 0.8em;
    background: $black;
    color: $white;
    border: 1px solid $white;
    transition: all 0.3s ease-out;
    outline: none;

    &:hover {
      background: $white;
      color: $black;
    }
  }

  &__image {
    &--wrp {
      position: relative;
    }
    &--img {
      width: 100%;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
    &--shadow {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 20%;
      background: linear-gradient(180deg, transparent, rgba(14, 14, 14, 0.6), $light-black);
    }

    &--buttonswrp {
      position: absolute;
      bottom: 10%;
      left: 1.5em;
      display: flex;
      align-items: center;

      @include mq('sm') {
        left: 2em;
      }
    }

    &--button {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      min-width: 120px;
      background-color: $red;
      color: $white;
      padding: 10px 16px;
      margin-left: 0;
      border-radius: 6px;
      border: 0;
      font-family: $main-font;
      font-size: 13px;
      font-weight: 500;
      cursor: pointer;
      text-decoration: none;
      transition: all 0.2s ease-out;

      @include mq('xs'){
        min-width: 150px;
        font-size: 14px;
        padding: 12px 20px;
      }

      @include mq('md'){
        font-size: 15px;
      }

      &:hover {
        background-color: darken($red, 5);
      }

      span {
        margin-left: 8px;
      }

      &-circular {
        display: inline-flex;
        padding: 10px;
        border-radius: 50%;
        font-size: 13px;
        cursor: pointer;
        margin: 0 5px;
        margin-left: 0.8em;
        background: transparent;
        color: $white;
        border: 1px solid $white;
        transition: all 0.3s ease-out;
        outline: none;

        @include mq('xs'){
          font-size: 14px;
        }

        &:hover {
          background: $white;
          color: $black;
        }

        &:first-child {
          margin-left: 0;
          background: $white;
          color: $black;

          &:hover {
            background: rgba($white, 0.7);
            border-color: rgba($white, 0.7);
          }
        }
      }
    }
  }

  &__info {
    &--wrp {
      padding: 1em 1.5em;

      @include mq('sm') {
        padding: 1.5em 2em;
      }
    }

    &--title {
      font-size: 22px;
      line-height: 1.4;
      font-weight: 600;
      margin-bottom: 15px;

      @include mq('sm') {
        font-size: 26px;
      }
    }

    &--description {
      font-size: 14px;
      line-height: 1.7;

      @include mq('sm') {
        font-size: 16px;
        line-height: 1.5;
      }
    }

    &--line {
      border-color: rgba(121,121,121,0.7);
      margin: 1em 0;

      @include mq('sm') {
        margin: 1.5em 0;
      }
    }

    &--otherTitle {
      font-size: 16px;
      line-height: 1.4;
      font-weight: 400;
      margin-bottom: 15px;

      @include mq('sm') {
        font-size: 20px;
      }
    }

    &--row {
      font-size: 12px;
      line-height: 1.6;
      margin: .5em;
      margin-left: 0;
      word-break: break-word;

      &-label {
        color: #777;
      }

      &-description {
        color: #ddd;
      }

      @include mq('sm') {
        font-size: 14px;
        line-height: 1.5;
      }
    }
  }
}